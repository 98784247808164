import { FormControl, FormGroup } from '@angular/forms';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { EnumState } from '../../isenum/enum.state';
import { EnumerationItem } from '../../isenum/enum.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { LoadEnumlist } from '../../isenum/enum.action';
import { MatDialog } from '@angular/material/dialog';
import { TreeNodeChildrenComponent } from '@ali-hm/angular-tree-component';

@Component({
  selector: 'isenumselect',
  templateUrl: './isenumselect.component.html',
  styleUrls: ['./isenumselect.component.scss'],
})
export class IsenumselectComponent extends IscontrolComponent implements OnInit, AfterViewInit {
  @Input() frmGrp: FormGroup;
  @Input() initialValue: string;
  @Input() label: string;
  _enum:string | null;
  @Input() set enum(value:string){
    this._enum = value;
    if (value != this.enum && value){
      this.store.dispatch(new LoadEnumlist(this.enum,null,this.account)); 
    }
 };
 get enum():string{
   return this._enum;
 }
  @Input() placeholder: string='';
  enumerationlist$: Observable<EnumerationItem[]>;

  controlid: number;
  @Input('control') formControl: FormControl;
  controlidID: number;
  controldescid: number;
  formControldesc: FormControl;
  datax: any[];
  //@ViewChild('selectoption') selectoption!: ElementRef;
  @ViewChild(ElementRef, { static: true }) selectoption: ElementRef;
  @Input('account') account=null;
  selectinit:boolean=false;
  translation: any | null = null;

  // formControlID: FormControl;
  // formControlDesc: FormControl;

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef,
    private renderer:Renderer2
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    
    if (this.formInput) {
      this.controlid = super.initControl(this.id.toString() + '.enumid', this.initialValue, 'enumid');
      this.formControl = super.getFormControl(this.controlid);
      this.controldescid = super.initControl(this.id.toString() + '.enumdesc', this.initialValue, 'enumdesc');
      this.formControldesc = super.getFormControl(this.controldescid);
    } else if(!this.formControl) {
      this.formControl = new FormControl(this.initialValue);
    } 

    if (this.enum) {
      this.store.dispatch(new LoadEnumlist(this.enum,null,this.account));
    }

    this.enumerationlist$ = this.store.select(EnumState.getenumlist).pipe(
      map((filterFn) => {
        this.datax = filterFn(this.enum);
        return this.datax;
      })
    );
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControl.value);
        });
      }
    }
  }

  enumValueChanges(val) {
    this.enumerationlist$ = this.store.select(EnumState.getenumlist).pipe(
      map((filterFn) => {
        return filterFn(val);
      })
    );
  }

  controlnameValueChanges(newVal) {
    this.compserv.formcontrols.get(this.controlid.toString()).name = newVal;
  }

  ngOnDestroy() {
    // super.destroyControlsNew([{controlname: this.controlname, initialValue: (this.initialValue ? this.initialValue : null), disabled: false}]);
  }

  ngAfterViewInit() {
    this.formControl.valueChanges.subscribe((a) => {
      if (a) {        
        this.setDesc(a);        
      }

    });
    // if (this.selectoption){
    //  this.renderer.addClass(this.selectoption.nativeElement,'is-'+this.id.toString()+'-selectoption');  
    // }

  }

  translate(lang){
    if (this.translation){
      this.label = this.translation[lang];
    }
  }

  setDesc(id) {
    if (this.datax?.length) {
      const en = this.datax.find((en) => en.id == id);
      if (this.formControldesc?.value != en?.description) {
        this.formControldesc.setValue(en ? en.description : null, { onlySelf: true });
      }
    }

  }
}
