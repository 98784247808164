import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  AfterViewInit,
  NgModule,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'iscolumn',
  templateUrl: './iscolumn.component.html',
  styleUrls: ['./iscolumn.component.scss'],
})
export class IscolumnComponent implements AfterViewInit {
  @ViewChild('columnContainer', { read: ViewContainerRef })
  public columnContainer: ViewContainerRef;
  @ViewChild('pivotContainer', { read: ViewContainerRef })
  public pivotContainer: ViewContainerRef;
  @ViewChild('totalContainer', { read: ViewContainerRef })
  public totalContainer: ViewContainerRef;
  columnkey: string;
  keyname: string;
  datapath: string;
  label = '';
  coltocol = true;
  draggable = false;
  public dispHeader = true;
  public dispBody = true;
  public dispFooter = true;
  isparent: IscolumnComponent;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.el.nativeElement.setAttribute('istag', 'iscolumn');
    //this.el.nativeElement.style['flex'] = '1';

    this.el.nativeElement.setAttribute('columnkey', this.columnkey);
    this.setScroll(0);
  }

  setScroll(scroll: number) {
    const translatey = `translatey(${scroll}px)`;
    this.renderer.setStyle(this.el.nativeElement, 'transform', translatey);
  }
}

export interface IsFieldDef {
  id: number;
  name: string;
  datatype: string;
  length: number;
}

export interface IsColumnDef {
  name: string;
  label: string;
  translation? : any;
  headervisible?: boolean;
  headercomp: string;
  bodycomp: string;
  bodycompconfig: any;
  footervisible?: boolean;
  footercomp: string;
  datatoedit: any;
  edittodata: any;
  editcomp: string;
  controlid: number;
  edit: boolean;
  selection: boolean;
  hidden?: boolean;
  dragstart?: boolean;
  width?: string;
  type?: string;
  class?: string;
  flex?: string;
  rowheight?: number;
  rowclass?: string;  
  columnclass?: string;
  headerclass?: string;
  footerclass?: string;
  footertext?: string;
  functions?: any;
  cellform?: any;
  formkey?: string;
  repeatfunction?: any;
  pivots?: any;
  renderdirection?: string;
  trackby?: number;
  enum?: string;
  datatype?: string;
  format?: string;
  classdefs: any;
  pivotData?: any;
  customfilter?: any;
  editfunction?: any;
  afteredit?: any;
  reducer?: string;
  path?: string;
  useinfilter?: boolean;
  sticky: number;
  stickycolor: string;
  columnwidth?: any;
  disablerowselected?: boolean;
}

export interface IsrowDef {
  name?: string;
  state?: string;
  functions?: any;
}

export interface IsColumnkeyDef {
  name?: string;
  functions?: any;
}

@NgModule({
  declarations: [IscolumnComponent],
  imports: [CommonModule],
})
class IsColumnModule {}
